import React, { FC } from 'react';
import { Link } from 'gatsby';

import Typography from 'components/elements/Typography';
import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsBenefitsItem } from './models';

import './BenefitsItem.scss';

const BenefitsItem: FC<IPropsBenefitsItem> = ({ data: [data] }) => {
  const { icon, title, text, link } = data;

  const BenefitsContent = (
    <>
      <div className="benefits-item__icon">
        <GatsbyImage image={icon} />
      </div>
      <Typography data={title} customClass="benefits-item__title" />
      {text?.length ? <Typography data={text} customClass="benefits-item__text" /> : null}
    </>
  );

  if (link?.length) {
    const { url, queryString } = link[0];
    const path = queryString ? `${url}${queryString}` : url;

    return (
      <Link to={path} data-testid="BenefitsItem" className="benefits-item benefits-item__link">
        {BenefitsContent}
      </Link>
    );
  }

  return (
    <div data-testid="BenefitsItem" className="benefits-item">
      {BenefitsContent}
    </div>
  );
};

export default BenefitsItem;
